// If you want to override variables do it here
@import './themes/colors.scss';
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui/scss/coreui.scss';
@import 'remixicon/fonts/remixicon.css';

// Some temp fixes
@import 'fixes';

// If you want to add something do it here
@import 'custom';

@import './component/button';
@import './component/input';
@import './component/card';
@import './component/pagination';
@import './component/sidebar';
@import './component/dashboard';
@import './component/table';
@import './component/chart';
@import './component/modal';
@import './component/checkbox';
@import './component/dropdown';
@import './icon_font';
@import './component/tab';
@import '../views/deviceCommands/style/deviceCommand';
@import '../views/firmwares/style/firmwares';
@import './component/timepicker';
@import './component/switch';
@import './component/toaster';

// Here you can add other styles

body,
.c-body {
    font-size: 14px;
    font-family: $font-base-family;
    &.light {
        color: $t-01-light;
        background-color: $background-01-light;
    }
    &.dark {
        color: $t-01;
        background-color: $background-01;
    }
}

.cursor-pointer {
    cursor: pointer;
}
.cursor-default {
    cursor: default;
}
.noHover {
    pointer-events: none;
}
.dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
    &.max {
        height: 11px;
        width: 11px;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0px;
}

.h1,
.h2,
.h3,
.h4 {
    font-weight: $font-weight-light;
    line-height: 120%;
}

.h2-bold,
.h3-bold,
.h4-bold {
    font-weight: $font-weight-bold;
    letter-spacing: 0.8px;
}

.font-large,
.font-large-bold,
.font-large-light {
    font-family: $font-base-family;
    font-size: $font-17;
    line-height: 120%;
}

.font-body,
.font-body-bold,
.font-body-light {
    font-family: $font-base-family;
    font-size: $font-14;
    line-height: 120%;
}

.font-base,
.font-base-bold,
.font-base-light {
    font-family: $font-base-family;
    font-size: $font-12;
    line-height: 120%;
}

.font-small,
.font-small-bold,
.font-small-light {
    font-family: $font-base-family;
    font-size: $font-10;
    line-height: 120%;
}

.font-xsmall,
.font-xsmall-medium {
    font-family: $font-base-family;
    font-size: $font-8;
    line-height: 120%;
}
.font-xlarge {
    font-family: $font-base-family;
    font-size: $font-20;
    line-height: 120%;
}

.font-body,
.font-large,
.font-base,
.font-small,
.font-xsmall {
    font-weight: $font-weight-light !important;
}

.font-body-bold,
.font-large-bold,
.font-base-bold,
.font-small-bold,
.font-xlarge {
    font-weight: $font-weight-bold !important;
    letter-spacing: 0.8px;
}

.font-body-light,
.font-large-light,
.font-base-light,
.font-small-light {
    font-weight: $font-weight-lighter !important;
}

.font-xsmall-medium {
    font-weight: $font-weight-normal !important;
}

.title-1,
.title-2 {
    font-weight: $font-weight-light !important;
    line-height: 120%;
}

.title-1-bold,
.title-2-bold {
    font-weight: $font-weight-bold !important;
    line-height: 120%;
    letter-spacing: 0.8px;
}

.title-1,
.title-1-bold {
    font-family: $font-base-family;
    font-size: $font-14;
}

.title-2,
.title-2-bold {
    font-family: $font-base-family;
    font-size: $font-12;
}

/// Old Variables
.font-display-large {
    font: $font-weight-bold 200px $font-base-family;
    letter-spacing: 0.8px;
}
.font-display-1 {
    font: $font-weight-bold $font-30 $font-base-family;
    line-height: $line-height-40;
    letter-spacing: 0.8px;
}

.font-display-2 {
    @extend .font-display-1;
    font-weight: $font-weight-lighter;
    line-height: $line-height-40;
}

.custom-loader {
    width: 1.2rem;
    height: 1.2rem;
}

.footnote {
    font: $font-weight-normal $font-10 $font-base-family;
    line-height: $line-height-16;
}
.w-2 {
    width: 2%;
}
.w-5 {
    width: 5%;
}
.w-8 {
    width: 8%;
}
.w-10 {
    width: 10%;
}
.w-12 {
    width: 12%;
}

.w-15 {
    width: 15%;
}

.w-18 {
    width: 18%;
}
.w-20 {
    width: 20%;
}
.w-22 {
    width: 22%;
}
.w-23 {
    width: 23%;
}
.w-23_5 {
    width: 23.5%;
}

.w-26 {
    width: 26%;
}
.w-27 {
    width: 27%;
}

.w-30 {
    width: 30%;
}
.w-32 {
    width: 32%;
}
.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-45 {
    width: 45%;
}

.w-60 {
    width: 60%;
}

.w-65 {
    width: 65%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 80%;
}
.w-85 {
    width: 85%;
}
.w-90 {
    width: 90%;
}
.w-100 {
    width: 100%;
}
.c-main {
    padding-top: 1rem;
}

@media (min-width: 768px) {
    .table-responsive {
        overflow: inherit;
    }
}

.gradient-1 {
    background: linear-gradient(140.14deg, #55b1fd 20.6%, #726adf 87%);
}

.gradient-2 {
    background: linear-gradient(#336a98 0%, rgba(51, 106, 152, 0) 100%);
}

.font-weight-semibold {
    font-weight: $font-weight-semibold;
}

.shadow-1 {
    box-shadow: $shadow-1;
}

.shadow-2 {
    box-shadow: $shadow-2;
}
.shadow-3 {
    box-shadow: $shadow-3;
}

.brown-color {
    color: #999699;
    border: 2px solid #999699;
    background-color: $black-400;
}

.text-overflow-elipses {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blockHead:after {
    color: $black;
    border-left: 40px solid;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    right: -40px;
    top: -20px;
}
.blockHead {
    background-color: $white;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    position: relative;
    justify-content: center;
    border: 2px solid $black;
}

.border-radius-2 {
    @include border-radius(2px);
}

.border-radius-4 {
    @include border-radius(4px);
}

.custom-pagination {
    font: $font-weight-normal $font-14 $font-base-family !important;
}

.custom-pagination-selected:focus {
    outline: none;
    box-shadow: none;
}

.opacity-80 {
    opacity: 0.8;
}

.auth-bg-img {
    position: absolute;
    width: 100%;
    height: 100%;
}

.line-height-16 {
    line-height: 1rem;
}

.padding-top-10 {
    padding-top: 10px !important;
}

/* Custom ScrollBar */
::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar:horizontal {
    height: 6px;
    @include border-radius(4px);
}
::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-corner {
    background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: $primary-600;
    @include border-radius(4px);
}

.battery {
    @include border-radius(4px);
    padding: 1px 4px 1px 4px;
}

.bar {
    @extend .border-radius-2;
    cursor: pointer;
    display: inline-block;
    width: 3px;
    height: 9px;
    margin-right: 2px;
}

.z-index-1 {
    z-index: 1;
}

/* Base ellipsis class with shared properties */
.text-ellipsis-base {
    /* Standard CSS ellipsis */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.left-elipsis {
    @extend .text-ellipsis-base;
    /* Beginning of string */
    direction: rtl;
    text-align: left;
}

.right-elipsis {
    @extend .text-ellipsis-base;
    /* Beginning of string */
    direction: ltr;
    text-align: right;
}

.border-dotted {
    border: 2px dotted $primary;
}

.border-2 {
    border-width: 2px !important;
}

.border-bottom-01 {
    border-bottom: 1px solid;
    &.light {
        border-bottom-color: $br-01-light;
    }
    &.dark {
        border-bottom-color: $br-01;
    }
}

.border-top-01 {
    border-top: 1px solid;
    &.light {
        border-top-color: $br-01-light;
    }
    &.dark {
        border-top-color: $br-01;
    }
}

hr {
    height: 0.1vh !important;
}

.device-card-hr {
    height: 0px !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.primary-500-hovered:hover {
    background-color: $success !important;
}

.ideal-hovered:hover {
    background-color: $ideal !important;
}
.hazardous-hovered:hover {
    background-color: $hazardous !important;
}
.moderate-hovered:hover {
    background-color: $moderate !important;
}
.healthy-hovered:hover {
    background-color: $healthy !important;
}

.bar-hover:hover {
    padding: 15px !important;
}

.border-radius-10 {
    @include border-radius(10px !important);
}

.border-radius-5 {
    @include border-radius(5px !important);
}
.auth-text-color {
    color: #ffc48a;
}

.dark {
    .gradient-1-rotated {
        background: linear-gradient(to bottom right, $background-03 50%, $background-02 50%);
    }
    .dotted-border-right {
        border-right: 1px dotted $br-01;
    }
    .dotted-border-bottom {
        border-bottom: 1px dotted $br-01;
    }
    .placeholder-color {
        color: $placeholder-color !important;
    }
}
.light {
    .gradient-1-rotated {
        background: linear-gradient(to bottom right, $background-03-light 50%, $background-02-light 50%);
    }
    .dotted-border-right {
        border-right: 1px dotted $br-01-light;
    }
    .dotted-border-bottom {
        border-bottom: 1px dotted $br-01;
    }
    .placeholder-color {
        color: $placeholder-color-light !important;
    }
}

.primary-hover:hover {
    background-color: $primary;
}

.background-transparent {
    background: transparent;
}

.reduce-left {
    left: -2px;
    border-left: 0px !important;
}

.login-bottom-bar {
    background-color: $bottom-bar;
    background-image: linear-gradient(to right, $bottom-bar, $background-01);
}
.dark-calender {
    color-scheme: dark;
}
.graph-label-alignment {
    overflow: scroll;
    width: 50%;
}

//Default Css for Slider component
.customSliderDefault {
    /* max width of your slider */
    max-width: 300px;
}
.customSliderDefault-track {
    /* Top value to align your track to the center of your thumb */
    top: 8px;
    /* thickness of the track */
    height: 4px;
    /* default color of your track */
    background: red;
}

.customSliderDefault-track.customSliderDefault-track-0 {
    /* color of the track before the thumb */
    background: blue;
}
@mixin no-box-shadow {
    box-shadow: none !important;
}
textarea {
    resize: none;
}
.no-border {
    border: none !important;
}
.full-height {
    height: 100vh;
}
.flex-shrink-0 {
    flex-shrink: 0;
}
.text-green {
    color: $t-03-light;
}
.text-align-start {
    text-align: start;
}
.device-icon-width {
    width: 1.875rem;
}
.opacity-60 {
    opacity: 60%;
}
.site-rhs-vertical-line {
    width: 0.063rem;
    height: 3rem;
}
.rhs_header_section {
    height: 3.625rem;
}
.site-title-max-width {
    max-width: 13.75rem;
}
.no-underline {
    text-decoration: none !important;
}
.dim-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: brightness(50%);
    z-index: 99;
    pointer-events: none;
}
.search-site-dropdown {
    width: 15.625rem !important;
}
.search-site-dropdown-height {
    height: 2.25rem !important;
}
.search-site-scrollbar {
    max-height: 15.625rem;
    overflow: scroll;
}

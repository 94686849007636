// Variable overrides
/******* Fonts *******/
//override coreUi base font

$font-base-family: 'IBM Plex Sans', sans-serif;

$font-size-base: 1rem;

$font-35: $font-size-base * 2.188;
$font-29: $font-size-base * 1.813;

$font-30: $font-size-base * 1.875;
$font-24: $font-size-base * 1.5;
$font-20: $font-size-base * 1.25;
$font-17: $font-size-base * 1.063;
$font-16: $font-size-base;
$font-14: $font-size-base * 0.875;
$font-12: $font-size-base * 0.75;
$font-10: $font-size-base * 0.625;
$font-8: $font-size-base * 0.5;

// override coreui h1-h6 font size
$h1-font-size: $font-35;
$h2-font-size: $font-29;
$h3-font-size: $font-24;
$h4-font-size: $font-20;

// custom line height
$line-height-40: $font-size-base * 2.5;
$line-height-16: $font-size-base;
$line-height-14: $font-size-base * 0.875;
$line-height-12: $font-size-base * 0.75;

$font-weight-lighter: 300;
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;

$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='#28b2e7'/%3E%3C/svg%3E");

$shadow-1: 0px 4px 12px rgba(0, 0, 0, 0.25);
$shadow-2: 0px 0px 8px #0f121f;
$shadow-3:0px 13px 10px 0px rgba(18, 20, 51, 0.04);

$sidebar-top: 57px;
$modal-backdrop-opacity: 0.7;

$dropdown-item-padding-x: 8px;
$dropdown-min-width: 0;
$sidebar-width: 380px;

$sidebar-widths: (
    xl: 45rem,
    lg: 38rem,
    sm: 14rem,
);

$dropdown-border-radius: 2px;
$dropdown-border-width: 1px;

$nav-tabs-border-width: 1px;
$nav-underline-border-width: 0;
$nav-link-padding-y: 0rem;

$popover-border-width: 1px;
$popover-border-radius: 4px;
$progress-height: 8px;
$progress-border-radius: 4px;
$table-border-width: 10px;
$table-cell-padding-y: 0.5rem;
$table-cell-padding-x: 0.5rem;
